import styled, { css } from 'styled-components';

import ProgressBar from '@Components/atoms/ProgressBar';
import Text from '@Components/atoms/Text';
import RewardBadge from '@Components/molecules/RewardBadge/RewardBadge';
import { Circle } from '@Components/molecules/RewardBadge/RewardBadge.styles';

import { ThemeType } from '@Assets/styles/theme/theme';

interface LevelProps {
  isFilled: boolean;
}

export const Root = styled.div<{ $isHighlighted: boolean }>`
  position: relative;
  border-radius: 0.625rem;
  max-width: 27.125rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.palette.achievement.background};
  border: 1px solid ${(props) => props.theme.palette.achievement.border};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    box-shadow ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};

  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    background: ${(props) =>
      props.theme.type === ThemeType.Light
        ? 'linear-gradient(100deg, transparent, #e6e6e65b, #ffffffff, #e6e6e65b, transparent)'
        : 'linear-gradient(100deg, transparent, #ffffff33, transparent)'};
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: 1s;
  }

  &:hover:before {
    left: 100%;
  }

  &:hover ${Circle} {
    filter: grayscale(0%);
  }

  ${(props) =>
    props.$isHighlighted &&
    css`
      box-shadow: 0 0 15px 0 ${(props) => props.theme.palette.achievement.primaryChart};
    `}
`;

export const StyledRewardBadge = styled(RewardBadge)`
  background-color: transparent !important;
  border: none !important;
`;

export const StyledRewardBadgeSkeleton = styled(RewardBadge.Skeleton)`
  background-color: transparent !important;
  border: none !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  height: 5.85rem;
  width: calc(100% - 6rem); // todo: this could be improved, so that we do not have to use fixed values
  padding: 0.8rem 0.8rem 0.8rem 0;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space.small_8};
`;

export const Title = styled(Text)`
  flex-grow: 1;
`;

export const PointsWrapper = styled.div`
  white-space: nowrap;
  min-width: 5.5rem;
`;

export const LevelContainer = styled.div`
  display: flex;
  gap: 0.2rem;
`;

export const Level = styled.div<LevelProps>`
  width: 0.9rem;
  height: 0.4rem;
  background-color: ${(props) =>
    props.isFilled
      ? props.theme.palette.achievement.progress.level.reached
      : props.theme.palette.achievement.progress.level.notReached};
  transition: background-color ${(props) => props.theme.transition.theme};
`;

export const StyledProgressBar = styled(ProgressBar)`
  background-color: ${(props) => props.theme.palette.achievement.progress.barBackground};
`;
