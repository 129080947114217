import { TypingSpeedNormalResponse } from '@Api-generated';

export default class TypingSpeedNormalModel implements TypingSpeedNormalResponse {
  public wpm: number;
  public languageIso: string;
  public testsTaken: number;

  constructor(wpm: number, languageIso: string, testsTaken: number) {
    this.wpm = wpm;
    this.languageIso = languageIso;
    this.testsTaken = testsTaken;
  }

  public static hydrateFromApi(typingSpeedNormal: TypingSpeedNormalResponse) {
    return new TypingSpeedNormalModel(
      typingSpeedNormal.wpm,
      typingSpeedNormal.languageIso,
      typingSpeedNormal.testsTaken
    );
  }
}
