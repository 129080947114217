import React from 'react';

import { PropsWithClassName } from '@Components/helper';

interface Props extends PropsWithClassName {}

const ChartReward3 = (props: Props): React.ReactElement => {
  return (
    <div className={props.className} style={{ display: 'flex' }} data-testid="chart-reward-3-root">
      <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1655.53 -91.404C1837.8 -91.404 2000.45 4.24652 2000.45 4.24652V254.402H-601.92V-213.044C-601.92 -213.044 -468.755 -77.5723 -335.01 -99.3445C-93.9434 -138.588 -119.311 25.5654 92.4613 25.5656C248.013 25.5658 341.646 -347.805 555.382 -347.065C769.118 -346.326 748.593 8.62012 1082.94 3.98587C1333.41 0.514282 1514.67 -91.404 1655.53 -91.404Z"
          fill="url(#paint0_linear_574_70368)"
          fillOpacity="0.2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_574_70368"
            x1="699.263"
            y1="-484.327"
            x2="716.429"
            y2="205.178"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D1B1E7" />
            <stop offset="1" stopColor="#333333" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default ChartReward3;
