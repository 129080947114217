import { AchievementCategory, AchievementType } from '@Api/models/UserAchievementModel';

import { AchievementResponse } from '@Api-generated';

export default class AchievementModel implements AchievementResponse {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public achievementType: AchievementType;
  public category: AchievementCategory;
  public isActive: boolean;
  public isVisible: boolean;
  public name: string;
  public description: string;
  public userPercentage: number;

  constructor(
    id: string,
    createdAt: string,
    updatedAt: string,
    achievementType: AchievementType,
    category: AchievementCategory,
    isActive: boolean,
    isVisible: boolean,
    name: string,
    description: string,
    userPercentage: number
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.achievementType = achievementType;
    this.category = category;
    this.isActive = isActive;
    this.isVisible = isVisible;
    this.name = name;
    this.description = description;
    this.userPercentage = userPercentage;
  }

  public static hydrateFromApi(achievementResponse: AchievementResponse): AchievementModel {
    const achievementType = achievementResponse.achievementType as AchievementType;
    const category = achievementResponse.category as AchievementCategory;

    return new AchievementModel(
      achievementResponse.id,
      achievementResponse.createdAt,
      achievementResponse.updatedAt,
      achievementType,
      category,
      achievementResponse.isActive,
      achievementResponse.isVisible,
      achievementResponse.name,
      achievementResponse.description,
      achievementResponse.userPercentage
    );
  }
}
