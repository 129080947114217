import React from 'react';

import CompetitorModel from '@Api/models/AchievementMetaData/CompetitorModel';
import FlawlessModel from '@Api/models/AchievementMetaData/FlawlessModel';
import GrinderModel from '@Api/models/AchievementMetaData/GrinderModel';
import PracticerModel from '@Api/models/AchievementMetaData/PracticerModel';
import RepeaterModel from '@Api/models/AchievementMetaData/RepeaterModel';
import TypingSpeedAdvancedModel from '@Api/models/AchievementMetaData/TypingSpeedAdvancedModel';
import TypingSpeedNormalModel from '@Api/models/AchievementMetaData/TypingSpeedNormalModel';
import WinnerModel from '@Api/models/AchievementMetaData/WinnerModel';
import UserAchievementModel, { AchievementType } from '@Api/models/UserAchievementModel';

import { Tooltip, TooltipContent, TooltipTrigger } from '@Components/atoms/Tooltip/Tooltip';
import { PropsWithClassName } from '@Components/helper';
import { getAchievementIcon } from '@Components/helper/achievement';
import ProgressAchievementBadge from '@Components/molecules/ProgressAchievementBadge/ProgressAchievementBadge';
import AchievementDetail from '@Components/molecules/RewardDetailBadge/AchievementDetail';

import { Root } from './ProgressAchievement.styles';

interface Props extends PropsWithClassName {
  userAchievementModel: UserAchievementModel;
  hideTooltip?: boolean;
  isHighlighted?: boolean;
}

const getPoints = (achievement: UserAchievementModel) => {
  const metaData = achievement.metaData;
  switch (achievement.achievementType) {
    case AchievementType.Progress_TypingTest_TypingSpeedNormal:
      if (metaData instanceof TypingSpeedNormalModel) {
        return metaData.wpm;
      }
      break;
    case AchievementType.Progress_TypingTest_TypingSpeedAdvanced:
      if (metaData instanceof TypingSpeedAdvancedModel) {
        return metaData.wpm;
      }
      break;
    case AchievementType.Progress_TypingTest_Grinder:
      if (metaData instanceof GrinderModel) {
        return metaData.testsTaken;
      }
      break;
    case AchievementType.Progress_TypingTest_Flawless:
      if (metaData instanceof FlawlessModel) {
        return metaData.wpm;
      }
      break;
    case AchievementType.Progress_Competition_Competitor:
      if (metaData instanceof CompetitorModel) {
        return metaData.participatedCount;
      }
      break;
    case AchievementType.Progress_Competition_Winner:
      if (metaData instanceof WinnerModel) {
        return metaData.competitionsWon;
      }
      break;
    case AchievementType.Progress_Competition_Repeater:
      if (metaData instanceof RepeaterModel) {
        return Math.max(
          ...metaData.participatedCompetitions.map((participatedCompetition) => participatedCompetition.testsTaken)
        );
      }
      break;
    case AchievementType.Progress_TextPractice_Practicer:
      if (metaData instanceof PracticerModel) {
        return metaData.participatedCount;
      }
      break;
  }

  return 0;
};

const ProgressAchievement = (props: Props): React.ReactElement => {
  const { userAchievementModel, hideTooltip = false, isHighlighted = false } = props;

  const points = getPoints(userAchievementModel);

  const getProgressAchievementBadge = () => {
    return (
      <div className={props.className} data-testid={'progress-achievement-root'}>
        <ProgressAchievementBadge
          title={userAchievementModel.title}
          achievementType={userAchievementModel.achievementType}
          stars={userAchievementModel.level}
          points={points}
          percent={userAchievementModel.progress ?? 0}
          levelMax={userAchievementModel.levelMax}
          createdAt={userAchievementModel.createdAt}
          dataTestId={'achievement-badge-' + userAchievementModel.achievementType}
          isHighlighted={isHighlighted}
        />
      </div>
    );
  };

  if (hideTooltip) {
    return getProgressAchievementBadge();
  }

  return (
    <Root className={props.className} data-testid={'progress-achievement-root'}>
      <Tooltip>
        <TooltipTrigger>{getProgressAchievementBadge()}</TooltipTrigger>
        <TooltipContent>
          <AchievementDetail
            achievement={userAchievementModel}
            icon={getAchievementIcon(userAchievementModel.achievementType)}
            points={points}
          />
        </TooltipContent>
      </Tooltip>
    </Root>
  );
};

export default ProgressAchievement;
