import styled, { css, DefaultTheme } from 'styled-components';

import ChartAchievementDetail1 from '@Components/atoms/Charts/ChartAchievementDetail1/ChartAchievementDetail1';
import ChartAchievementDetail2 from '@Components/atoms/Charts/ChartAchievementDetail2/ChartAchievementDetail2';
import ChartAchievementDetail3 from '@Components/atoms/Charts/ChartAchievementDetail3/ChartAchievementDetail3';
import ChartRewardGenerated from '@Components/atoms/Charts/ChartRewardGenerated/ChartRewardGenerated';
import Headline from '@Components/atoms/Headline';
import { Icon } from '@Components/atoms/Icon/Icon';
import Text from '@Components/atoms/Text';
import { getContrastTextColor } from '@Components/helper/styleHelper';

export const Root = styled.div`
  padding: ${(props) => props.theme.space.xs_4};
  width: 18rem;
`;

export const Badge = styled.div<{ $isDisabled: boolean }>`
  width: 17.5rem;
  height: 9.375rem;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.achievement.backgroundBadge};
  transition: background-color ${(props) => props.theme.transition.theme};

  margin-bottom: ${(props) => props.theme.space.default_16};

  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.5;
      filter: grayscale(100%);
    `}
`;

export const StyledChartRewardGenerated = styled(ChartRewardGenerated)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
`;

export const StyledChartRewardDetail1 = styled(ChartAchievementDetail1)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledChartRewardDetail2 = styled(ChartAchievementDetail2)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledChartRewardDetail3 = styled(ChartAchievementDetail3)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.palette.achievement.backgroundCircle};
  width: 100%;
  height: 100%;
  clip-path: circle(25% at center);
  transition: background-color 400ms ease-in;
`;

export const BadgeIcon = styled.div<{ $src: string }>`
  background: url(${(props) => props.$src});
  background-size: cover;
  width: 40%;
  height: 75%;
  mix-blend-mode: screen;
`;

export const StyledText = styled(Text)`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space.small_8};
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

const getColorByRarity = (userPercentage: number, theme: DefaultTheme) => {
  if (userPercentage > 60) {
    return theme.palette.achievement.rarity.common;
  }

  if (userPercentage > 40) {
    return theme.palette.achievement.rarity.uncommon;
  }

  if (userPercentage > 20) {
    return theme.palette.achievement.rarity.rare;
  }

  if (userPercentage > 5) {
    return theme.palette.achievement.rarity.epic;
  }

  return theme.palette.achievement.rarity.legendary;
};

export const UserPercentageContainer = styled.span<{ $userPercentage: number }>`
  display: inline-flex;
  align-items: center;
  padding: 3px 7px;
  border-radius: 10px;
  text-align: center;
  margin-left: auto;

  background-color: ${(props) => getColorByRarity(props.$userPercentage, props.theme)};
`;

export const StyledIcon = styled(Icon)<{ $userPercentage: number }>`
  color: ${(props) => getContrastTextColor(getColorByRarity(props.$userPercentage, props.theme))};
  margin-right: ${(props) => props.theme.space.xs_4};
`;

export const UserPercentage = styled(Text)<{ $userPercentage: number }>`
  color: ${(props) => getContrastTextColor(getColorByRarity(props.$userPercentage, props.theme))};
`;
