import styled from 'styled-components';

import { TextColor } from '@Helpers/types/text';

interface IconBackgroundProps {
  readonly $active?: boolean;
  readonly $color: TextColor;
  readonly $size: 'small' | 'medium' | 'large';
}

const getPosition = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return '-2px';
    case 'medium':
      return '-7px';
    case 'large':
      return '-12px';
  }
};

const getPadding = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 0;
    case 'medium':
      return '5px';
    case 'large':
      return '10px';
  }
};

export const IconBackground = styled.div<IconBackgroundProps>`
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    top: ${(props) => getPosition(props.$size)};
    left: ${(props) => getPosition(props.$size)};
    width: 1.5rem;
    height: 1.5rem;
    padding: ${(props) => getPadding(props.$size)};
    border-radius: 100%;
    content: ' ';
    background: ${(props) => (props.$active ? props.theme.palette.input.background.active : 'none')};
    opacity: ${(props) => (props.$active ? 1 : 0)};
    transition:
      opacity 200ms ease-in-out,
      background-color ${(props) => props.theme.transition.theme};
  }

  &:hover::before {
    background: ${(props) =>
      props.$active ? props.theme.palette.input.background.active : props.theme.palette.input.background.hover};
    opacity: 1;
  }

  svg {
    position: relative;
    display: flex;
    color: ${(props) => props.theme.palette.text[props.$color]};
    transition:
      fill ${(props) => props.theme.transition.theme},
      color ${(props) => props.theme.transition.theme};
    width: 1.25rem;
    height: 1.25rem;
  }
`;
