import UserModel from '@Api/models/UserModel';

import { UserNonCompliantResponse } from '@Api-generated';

export default class UserNonCompliantModel implements UserNonCompliantResponse {
  public moderator: UserModel;
  public oldUsername: string | null;

  constructor(moderator: UserModel, oldUsername: string | null) {
    this.moderator = moderator;
    this.oldUsername = oldUsername;
  }

  public static hydrateFromApi(userNonCompliantResponse: UserNonCompliantResponse): UserNonCompliantModel {
    const moderator = UserModel.hydrateFromApi(userNonCompliantResponse.moderator);

    return new UserNonCompliantModel(moderator, userNonCompliantResponse.oldUsername);
  }
}
