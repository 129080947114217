import styled from 'styled-components';

interface ProgressProps {
  width: number;
}

export const Root = styled.div`
  width: 100%;
  height: 0.625rem;
  background-color: ${(props) => props.theme.palette.input.bar.background};
  border-radius: 5px; // todo: theme?
  transition: background-color ${(props) => props.theme.transition.theme};
`;

export const Progress = styled.div<ProgressProps>`
  width: ${(props) => props.width}%;
  height: 0.625rem;
  background-color: ${(props) => props.theme.palette.input.bar.progress};
  border-radius: 5px;
  transition: background-color ${(props) => props.theme.transition.theme};
`;
