import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropsWithClassName } from '@Components/helper';
import { DateTimeFormats, formatDate, getDateInTimezone } from '@Components/helper/date';

import { Root } from './DateTime.styles';

interface Props extends PropsWithClassName {
  date: string;
  dateFormat?: DateTimeFormats;
}

const DateTime = (props: Props): React.ReactElement => {
  const { date, dateFormat } = props;

  const { i18n } = useTranslation();

  return (
    <Root className={props.className} dateTime={getDateInTimezone(date).toISOString()} data-testid={'DateTime-root'}>
      {formatDate(date, i18n.language, dateFormat)}
    </Root>
  );
};

export { DateTime };
