import { TypingSpeedAdvancedResponse } from '@Api-generated';

export default class TypingSpeedAdvancedModel implements TypingSpeedAdvancedResponse {
  public wpm: number;
  public languageIso: string;
  public testsTaken: number;

  constructor(wpm: number, languageIso: string, testsTaken: number) {
    this.wpm = wpm;
    this.languageIso = languageIso;
    this.testsTaken = testsTaken;
  }

  public static hydrateFromApi(typingSpeedAdvanced: TypingSpeedAdvancedResponse) {
    return new TypingSpeedAdvancedModel(
      typingSpeedAdvanced.wpm,
      typingSpeedAdvanced.languageIso,
      typingSpeedAdvanced.testsTaken
    );
  }
}
