import {
  AchievementLevelUpResponse,
  AchievementUnlockedNotificationResponse,
  AntiCheatToUnlockResultResponse,
  CompetitionResultNotificationResponse,
  CompetitionWonResponse,
  NotificationResponse,
} from '@Root/src/api-generated';

import AchievementLevelUpModel from '@Api/models/NotificationMetaData/AchievementLevelUpModel';
import AchievementUnlockedNotificationModel from '@Api/models/NotificationMetaData/AchievementUnlockedNotificationModel';
import AntiCheatToUnlockResultModel from '@Api/models/NotificationMetaData/AntiCheatToUnlockResultModel';
import CompetitionResultNotificationModel from '@Api/models/NotificationMetaData/CompetitionResultNotificationModel';
import CompetitionWonModel from '@Api/models/NotificationMetaData/CompetitionWonModel';
import UserNonCompliantModel from '@Api/models/NotificationMetaData/UserNonCompliantModel';
import UserModel from '@Api/models/UserModel';

// todo: replace with generated enum from backend as soon as native enums are supported in OpenApi
export enum NotificationType {
  // MAIL NOTIFICATIONS - are transmitted via mail
  Newsletter_Mail = 'newsletter_mail',
  WeeklyStatistics_Mail = 'weeklyStatistics_mail',

  // TOAST NOTIFICATIONS - mainly used for achievements. Are displayed via toast inside the app in the top left corner
  ProgressAchievementUnlocked_Toast = 'progressAchievementUnlocked_toast',
  ProgressAchievementLevelUp_Toast = 'progressAchievementLevelUp_toast',
  RewardAchievementUnlocked_Toast = 'rewardAchievementUnlocked_toast',

  // APP NOTIFICATIONS - are displayed inside the notification center of the app
  AntiCheatRequired_App = 'antiCheatRequired_app',
  CompetitionResult_App = 'competitionResult_app',
  CompetitionWon_App = 'competitionWon_app',
  ProgressAchievementUnlocked_App = 'progressAchievementUnlocked_app',
  ProgressAchievementLevelUp_App = 'progressAchievementLevelUp_app',
  RewardAchievementUnlocked_App = 'rewardAchievementUnlocked_app',
  UserIsCheater_App = 'userIsCheater_App',
  UserHasNonCompliantProfile_App = 'userHasNonCompliantProfile_App',
  UserHasNonCompliantUsername_App = 'userHasNonCompliantUsername_App',
  UserIsSpammer_App = 'userIsSpammer_App',
  RevokeUserIsCheater_App = 'revokeUserIsCheater_App',
  RevokeUserHasNonCompliantProfile_App = 'revokeUserHasNonCompliantProfile_App',
  RevokeUserHasNonCompliantUsername_App = 'revokeUserHasNonCompliantUsername_App',
  RevokeUserIsSpammer_App = 'revokeUserIsSpammer_App',
}

export type NotificationMetaData =
  | AchievementLevelUpModel
  | AchievementUnlockedNotificationModel
  | AntiCheatToUnlockResultModel
  | CompetitionResultNotificationModel
  | CompetitionWonModel
  | UserNonCompliantModel;

export default class NotificationModel implements NotificationResponse {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public notificationType: NotificationType;
  public title: string | null;
  public message: string;
  public metaData: NotificationMetaData | null;
  public readAt: string | null;
  public deletedAt: string | null;
  public user: UserModel;

  constructor(
    id: string,
    createdAt: string,
    updatedAt: string,
    notificationType: NotificationType,
    title: string | null,
    message: string,
    metaData: NotificationMetaData | null,
    readAt: string | null,
    deletedAt: string | null,
    user: UserModel
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.notificationType = notificationType;
    this.title = title;
    this.message = message;
    this.metaData = metaData;
    this.readAt = readAt;
    this.deletedAt = deletedAt;
    this.user = user;
  }

  public static hydrateFromApi(notificationResponse: NotificationResponse) {
    const userModel = UserModel.hydrateFromApi(notificationResponse.user);

    const notificationType = notificationResponse.notificationType as NotificationType;

    const metaDataModel = this.hydrateSerializable(notificationResponse, notificationType);

    return new NotificationModel(
      notificationResponse.id,
      notificationResponse.createdAt,
      notificationResponse.updatedAt,
      notificationType,
      notificationResponse.title,
      notificationResponse.message,
      metaDataModel,
      notificationResponse.readAt,
      notificationResponse.deletedAt,
      userModel
    );
  }

  public static hydrateSerializable(
    notificationResponse: NotificationResponse,
    notificationType: NotificationType
  ): NotificationMetaData | null {
    switch (notificationType) {
      case NotificationType.ProgressAchievementUnlocked_App:
      case NotificationType.ProgressAchievementUnlocked_Toast:
      case NotificationType.RewardAchievementUnlocked_App:
      case NotificationType.RewardAchievementUnlocked_Toast:
        return AchievementUnlockedNotificationModel.hydrateFromApi(
          notificationResponse.metaData as AchievementUnlockedNotificationResponse
        );
      case NotificationType.ProgressAchievementLevelUp_App:
      case NotificationType.ProgressAchievementLevelUp_Toast:
        return AchievementLevelUpModel.hydrateFromApi(notificationResponse.metaData as AchievementLevelUpResponse);
      case NotificationType.AntiCheatRequired_App:
        return AntiCheatToUnlockResultModel.hydrateFromApi(
          notificationResponse.metaData as AntiCheatToUnlockResultResponse
        );
      case NotificationType.CompetitionResult_App:
        return CompetitionResultNotificationModel.hydrateFromApi(
          notificationResponse.metaData as CompetitionResultNotificationResponse
        );
      case NotificationType.CompetitionWon_App:
        return CompetitionWonModel.hydrateFromApi(notificationResponse.metaData as CompetitionWonResponse);
      case NotificationType.UserIsCheater_App:
      case NotificationType.UserHasNonCompliantProfile_App:
      case NotificationType.UserHasNonCompliantUsername_App:
      case NotificationType.UserIsSpammer_App:
      case NotificationType.RevokeUserIsCheater_App:
      case NotificationType.RevokeUserHasNonCompliantProfile_App:
      case NotificationType.RevokeUserHasNonCompliantUsername_App:
      case NotificationType.RevokeUserIsSpammer_App:
        if (!notificationResponse.metaData) {
          return null;
        }

        return UserNonCompliantModel.hydrateFromApi(notificationResponse.metaData as UserNonCompliantModel);
      default:
        return null;
    }
  }
}
