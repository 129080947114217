import React from 'react';

import { PropsWithClassName } from '@Components/helper';

interface Props extends PropsWithClassName {}

const ChartReward1 = (props: Props): React.ReactElement => {
  return (
    <div className={props.className} style={{ display: 'flex' }} data-testid="chart-reward-1-root">
      <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1507.47 437.964C1679.85 497.181 1802.6 640.484 1802.6 640.484L1721.33 877.07L-739.87 31.6046L-588.005 -410.484C-588.005 -410.484 -506.076 -239.098 -372.513 -216.238C-131.774 -175.034 -209.096 -28.0269 -8.81128 40.7746C138.302 91.3108 348.158 -231.386 550.059 -161.247C751.961 -91.1087 617.234 237.915 934.953 342.157C1172.96 420.247 1374.25 392.202 1507.47 437.964Z"
          fill="url(#paint0_linear_574_70365)"
          fillOpacity="0.7"
        />
        <defs>
          <linearGradient
            id="paint0_linear_574_70365"
            x1="730.729"
            y1="-244.318"
            x2="522.956"
            y2="413.36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DAB6E8" />
            <stop offset="1" stopColor="#333333" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default ChartReward1;
