import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
`;

export const Path = styled.path`
  fill: ${(props) => props.theme.palette.achievement.primaryChart};
  transition: fill ${(props) => props.theme.transition.theme};
`;
