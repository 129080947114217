import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropsWithClassName } from '@Components/helper';
import { getDistance } from '@Components/helper/date';
import { englishSingleOptionFallback } from '@Components/helper/language';

import { Root } from './DateTimeDistance.styles';

interface Props extends PropsWithClassName {
  distanceDate: string;
  baseDate?: Date;
  timezone?: string;
  addSuffix?: boolean;
}

const DateTimeDistance = (props: Props): React.ReactElement => {
  const { distanceDate, baseDate, timezone, addSuffix } = props;

  const { i18n } = useTranslation();
  const locale = i18n?.language ?? englishSingleOptionFallback.value; // todo: handle custom set language in user profile?

  return (
    <Root className={props.className} data-testid={'DateTimeDistance-root'}>
      {getDistance(distanceDate, locale, baseDate, timezone, addSuffix)}
    </Root>
  );
};

export { DateTimeDistance };
