import { PracticerResponse } from '@Api-generated';

export default class PracticerModel implements PracticerResponse {
  public participatedCount: number;

  constructor(participatedCount: number) {
    this.participatedCount = participatedCount;
  }

  public static hydrateFromApi(practicerResponse: PracticerResponse) {
    return new PracticerModel(practicerResponse.participatedCount);
  }
}
