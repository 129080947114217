import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Progress, Root } from './ProgressBar.styles';

interface Props extends PropsWithClassName {
  progress: number;
}

const ProgressBar = (props: Props): React.ReactElement => {
  const { progress } = props;

  return (
    <Root className={props.className} data-testid={'ProgressBar-root'}>
      <Progress width={progress} />
    </Root>
  );
};

export { ProgressBar };
