import UserAchievementModel from '@Api/models/UserAchievementModel';

import { AchievementLevelUpResponse } from '@Api-generated';

export default class AchievementLevelUpModel implements AchievementLevelUpResponse {
  public userAchievement: UserAchievementModel;

  constructor(userAchievement: UserAchievementModel) {
    this.userAchievement = userAchievement;
  }

  public static hydrateFromApi(achievementLevelUpResponse: AchievementLevelUpResponse) {
    const userAchievementModel = UserAchievementModel.hydrateFromApi(achievementLevelUpResponse.userAchievement);

    return new AchievementLevelUpModel(userAchievementModel);
  }
}
