import { CompetitorResponse } from '@Api-generated';

export default class CompetitorModel implements CompetitorResponse {
  public participatedCount: number;
  public participatedCompetitions: string[];

  constructor(participatedCount: number, participatedCompetitions: string[]) {
    this.participatedCount = participatedCount;
    this.participatedCompetitions = participatedCompetitions;
  }

  public static hydrateFromApi(competitorResponse: CompetitorResponse) {
    return new CompetitorModel(competitorResponse.participatedCount, competitorResponse.participatedCompetitions);
  }
}
