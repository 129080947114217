import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';

import { AchievementType } from '@Api/models/UserAchievementModel';

import Skeleton from '@Components/atoms/Skeleton';
import { PropsWithClassName } from '@Components/helper';

import useRouter from '@Helpers/i18n/useRouter';

import {
  Background,
  Circle,
  Icon,
  Root,
  StyledChartReward1,
  StyledChartReward2,
  StyledChartReward3,
  StyledChartRewardGenerated,
} from './RewardBadge.styles';

interface Props extends PropsWithClassName {
  achievementType: AchievementType;
  icon: string;
  isDisabled?: boolean;
  size: 'small' | 'default';
  isHighlighted?: boolean;
}

const RewardBadge = (props: Props): React.ReactElement => {
  const { achievementType, icon, isDisabled = false, size, isHighlighted = false } = props;
  const router = useRouter();
  const pathname = usePathname();

  const [isHighlightedState, setIsHighlightedState] = React.useState<boolean>(isHighlighted);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsHighlightedState(isHighlighted);

    if (isHighlighted && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isHighlighted]);

  const handleHover = async () => {
    if (!isHighlightedState) {
      return;
    }

    router.replaceShallow(pathname ?? '');
    setIsHighlightedState(false);
  };

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId ?? 'reward-badge-root'}
      $isDisabled={isDisabled}
      $size={size}
      $isHighlighted={isHighlightedState}
      ref={ref}
      onMouseEnter={handleHover}
    >
      <Background $size={size} $isDisabled={isDisabled}>
        <StyledChartReward1 $size={size} />
        <StyledChartReward2 $size={size} />
        <StyledChartReward3 $size={size} />
        <StyledChartRewardGenerated seed={achievementType} width={70} height={25} $size={size} />
        <Circle $isDisabled={isDisabled}>
          <Icon $src={icon} />
        </Circle>
      </Background>
    </Root>
  );
};

const RewardBadgeSkeleton = (props: PropsWithClassName & { size: 'small' | 'default' }): React.ReactElement => {
  const size = props.size === 'default' ? '4.25rem' : '2.125rem';

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId ?? 'reward-badge-root'}
      $size={props.size}
      $isHighlighted={false}
      $isDisabled={false}
    >
      <Skeleton width={size} height={size} />
    </Root>
  );
};

RewardBadge.Skeleton = RewardBadgeSkeleton;

export default RewardBadge;
