import LanguageModel from '@Api/models/LanguageModel';
import UserModel from '@Api/models/UserModel';

import { CompetitionResponse, duration } from '@Api-generated';

import { removeSeparator } from '@Components/helper/gameModeService';

export enum CompetitionStatus {
  Running = 'running',
  Pending = 'pending',
  Completed = 'completed',
}

export enum CompetitionDuration {
  _24 = duration._24,
  _72 = duration._72,
  _168 = duration._168,
}

export default class CompetitionModel implements CompetitionResponse {
  public id: string;
  public urlHash: string;
  public createdAt?: string;
  public updatedAt?: string;
  public startsAt?: string;
  public endsAt?: string;
  public words?: string;
  public isPrivate?: boolean;
  public duration?: CompetitionDuration;
  public status?: CompetitionStatus;
  public participants?: number;
  public testsTaken?: number;
  public creator?: UserModel;
  public language?: LanguageModel;
  public winner?: UserModel | null;

  constructor(
    id: string,
    urlHash: string,
    createdAt?: string,
    updatedAt?: string,
    startsAt?: string,
    endsAt?: string,
    words?: string,
    isPrivate?: boolean,
    duration?: CompetitionDuration,
    status?: CompetitionStatus,
    participants?: number,
    testsTaken?: number,
    creator?: UserModel,
    language?: LanguageModel,
    winner?: UserModel | null
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.urlHash = urlHash;
    this.words = words;
    this.isPrivate = isPrivate;
    this.duration = duration;
    this.status = status;
    this.participants = participants;
    this.testsTaken = testsTaken;
    this.creator = creator;
    this.language = language;
    this.winner = winner;
  }

  public static hydrateFromApi(competitionResponse: CompetitionResponse) {
    const creatorModel = competitionResponse.creator
      ? UserModel.hydrateFromApi(competitionResponse.creator)
      : undefined;

    const languageModel = competitionResponse.language
      ? LanguageModel.hydrateFromApi(competitionResponse.language)
      : competitionResponse.language;

    const winnerModel = competitionResponse.winner
      ? UserModel.hydrateFromApi(competitionResponse.winner)
      : competitionResponse.winner;

    const status = (competitionResponse.status as CompetitionStatus) ?? CompetitionStatus.Pending;

    const duration = competitionResponse.duration
      ? (competitionResponse.duration as CompetitionDuration)
      : CompetitionDuration._24;

    return new CompetitionModel(
      competitionResponse.id,
      competitionResponse.urlHash,
      competitionResponse.createdAt,
      competitionResponse.updatedAt,
      competitionResponse.startsAt,
      competitionResponse.endsAt,
      competitionResponse.words,
      competitionResponse.isPrivate,
      duration,
      status,
      competitionResponse.participants,
      competitionResponse.testsTaken,
      creatorModel,
      languageModel,
      winnerModel
    );
  }

  public getWordsWithoutSeparator() {
    if (!this.words) {
      return undefined;
    }

    return removeSeparator(this.words);
  }
}
