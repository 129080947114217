import styled from 'styled-components';

import { TextColor } from '@Helpers/types/text';

export const Root = styled.div<{ $color: TextColor; $hoverColor?: TextColor; $isPointer: boolean }>`
  cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};
  color: ${(props) => props.theme.palette.text[props.$color]};
  transition:
    fill ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme};

  @media (hover: hover) {
    &:hover {
      ${(props) => (props.$hoverColor ? `color: ${props.theme.palette.text[props.$hoverColor]}` : '')}
    }
  }
`;
