import CompetitionModel from '@Api/models/CompetitionModel';
import UserModel from '@Api/models/UserModel';

import { CompetitionOnUserResponse } from '@Api-generated';

export default class CompetitionOnUserModel implements CompetitionOnUserResponse {
  public id: string;
  public tries: number;
  public position: number;
  public competition?: CompetitionModel;
  public user?: UserModel;

  constructor(id: string, tries: number, position: number, competition?: CompetitionModel, user?: UserModel) {
    this.id = id;
    this.tries = tries;
    this.position = position;
    this.competition = competition;
    this.user = user;
  }

  public static hydrateFromApi(competitionOnUserResponse: CompetitionOnUserResponse) {
    const competitionModel = competitionOnUserResponse.competition
      ? CompetitionModel.hydrateFromApi(competitionOnUserResponse.competition)
      : competitionOnUserResponse.competition;

    const userModel = competitionOnUserResponse.user
      ? UserModel.hydrateFromApi(competitionOnUserResponse.user)
      : competitionOnUserResponse.user;

    return new CompetitionOnUserModel(
      competitionOnUserResponse.id,
      competitionOnUserResponse.tries,
      competitionOnUserResponse.position,
      competitionModel,
      userModel
    );
  }
}
