'use client';

import { IconShare } from '@tabler/icons-react';
import React from 'react';

import UserAchievementModel from '@Api/models/UserAchievementModel';

import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { getAchievementIcon } from '@Components/helper/achievement';
import { getDynamicSubRoute } from '@Components/helper/url';
import RewardBadge from '@Components/molecules/RewardBadge/RewardBadge';
import { Routes } from '@Components/Routes';

import useRouter from '@Helpers/i18n/useRouter';
import { ShareMode } from '@Helpers/types/share';
import { TextSize } from '@Helpers/types/text';

import { Root, StyledIconButton } from './RewardNotification.styles';

interface Props extends PropsWithClassName {
  message: string;
  achievement: UserAchievementModel;
}

const RewardNotification = (props: Props): React.ReactElement => {
  const { message, achievement } = props;
  const router = useRouter();

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'reward-notification-root'}>
      <RewardBadge
        achievementType={achievement.achievementType}
        icon={getAchievementIcon(achievement.achievementType)}
        size="default"
      />
      <Text size={TextSize.Medium}>{message}</Text>
      <StyledIconButton
        onClick={() => router.push(getDynamicSubRoute(Routes.Share, ShareMode.Achievement, achievement.id, 'image'))}
      >
        <IconShare />
      </StyledIconButton>
    </Root>
  );
};

export default RewardNotification;
