import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AchievementType } from '@Api/models/UserAchievementModel';

import DateTimeDistance from '@Components/atoms/DateTime/DateTimeDistance';
import Row from '@Components/atoms/Row';
import Skeleton from '@Components/atoms/Skeleton';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { getAchievementIcon } from '@Components/helper/achievement';
import { getStringWithLength } from '@Components/helper/utils';

import useRouter from '@Helpers/i18n/useRouter';
import { TextColor, TextSize, TextTransform } from '@Helpers/types/text';

import {
  Content,
  Level,
  LevelContainer,
  PointsWrapper,
  Root,
  StyledProgressBar,
  StyledRewardBadge,
  StyledRewardBadgeSkeleton,
  Title,
  TitleRow,
} from './ProgressAchievementBadge.styles';

interface Props extends PropsWithClassName {
  title: string;
  stars: number;
  percent: number;
  points: number;
  levelMax: number | null;
  createdAt: string;
  achievementType: AchievementType;
  isHighlighted?: boolean;
}

const ProgressAchievementBadge = (props: Props): React.ReactElement => {
  const { title, stars, percent, points, levelMax, achievementType, createdAt, isHighlighted = false } = props;
  const router = useRouter();
  const pathname = usePathname();

  const [isHighlightedState, setIsHighlightedState] = React.useState<boolean>(isHighlighted);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsHighlightedState(isHighlighted);

    if (isHighlighted && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isHighlighted]);

  const handleHover = async () => {
    if (!isHighlightedState) {
      return;
    }

    router.replaceShallow(pathname ?? '');
    setIsHighlightedState(false);
  };

  const { t } = useTranslation('global');

  const renderLevels = (): React.ReactNode => {
    const starsMax = 5;

    return (
      <LevelContainer>
        {[...Array(starsMax)].map((e, i) => (
          <Level key={'star' + i} isFilled={i <= stars} />
        ))}
      </LevelContainer>
    );
  };

  const getSubtitle = () => {
    // todo: add "createdAt" as an argument to the translation. For this the DateTimeDistance component should be refactored to return just a string
    return `${t('progress-achievements.next_unlock', { nextUnlockAt: levelMax ? levelMax - points : 0 })} - ${t(
      'progress-achievements.unlocked_at'
    )} `;
  };

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId ?? 'progress-achievement-badge-root'}
      $isHighlighted={isHighlightedState}
      ref={ref}
      onMouseEnter={handleHover}
    >
      <div>
        <StyledRewardBadge
          achievementType={achievementType}
          icon={getAchievementIcon(achievementType)}
          size="default"
        />
      </div>
      <Content>
        <TitleRow>
          <Title size={TextSize.Medium} textTransform={TextTransform.Uppercase} isTruncated>
            {title}
          </Title>
          <PointsWrapper>
            <Text as="span" size={TextSize.Medium}>
              {`${points} `}
            </Text>
            {levelMax && (
              <Text as="span" size={TextSize.Medium} color={TextColor.Tertiary}>
                / {levelMax}
              </Text>
            )}
          </PointsWrapper>
          {renderLevels()}
        </TitleRow>
        <Row noGutters>
          <StyledProgressBar progress={percent} />
        </Row>
        <Row noGutters>
          <Text size={TextSize.Small} color={TextColor.Tertiary} isTruncated>
            {getSubtitle()}
            <DateTimeDistance distanceDate={createdAt} addSuffix />
          </Text>
        </Row>
      </Content>
    </Root>
  );
};

const ProgressAchievementBadgeSkeleton = (props: PropsWithClassName): React.ReactElement => {
  const renderLevels = (): React.ReactNode => {
    const starsMax = 5;

    return (
      <LevelContainer>
        {[...Array(starsMax)].map((e, i) => (
          <Level key={'star' + i} isFilled={false} />
        ))}
      </LevelContainer>
    );
  };

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId ?? 'progress-achievement-badge-root'}
      $isHighlighted={false}
      onMouseEnter={() => null}
    >
      <div>
        <StyledRewardBadgeSkeleton size="default" />
      </div>
      <Content>
        <TitleRow>
          <Skeleton>
            <Title size={TextSize.Medium} isTruncated>
              {getStringWithLength(10)}
            </Title>
          </Skeleton>
          <PointsWrapper>
            <Skeleton>
              <Text size={TextSize.Small}>{getStringWithLength(7)}</Text>
            </Skeleton>
          </PointsWrapper>
          {renderLevels()}
        </TitleRow>
        <Row noGutters>
          <StyledProgressBar progress={0} />
        </Row>
        <Row noGutters>
          <Skeleton width="100%">
            <Title size={TextSize.Xs} textTransform={TextTransform.Uppercase} isTruncated>
              {getStringWithLength(1)}
            </Title>
          </Skeleton>
        </Row>
      </Content>
    </Root>
  );
};

ProgressAchievementBadge.Skeleton = ProgressAchievementBadgeSkeleton;

export default ProgressAchievementBadge;
