import CompetitionResultModel from '@Api/models/CompetitionResultModel';
import TypingTestResultModel from '@Api/models/TypingTestResultModel';

import { AntiCheatToUnlockResultResponse } from '@Api-generated';

import { AntiCheatGameResult } from '@Helpers/result';

export default class AntiCheatToUnlockResultModel implements AntiCheatToUnlockResultResponse {
  public result: AntiCheatGameResult;

  constructor(result: AntiCheatGameResult) {
    this.result = result;
  }

  public static hydrateFromApi(antiCheatToUnlockResultResponse: AntiCheatToUnlockResultResponse) {
    let resultModel;

    if ('competition' in antiCheatToUnlockResultResponse.result) {
      resultModel = CompetitionResultModel.hydrateFromApi(antiCheatToUnlockResultResponse.result);
    } else {
      resultModel = TypingTestResultModel.hydrateFromApi(antiCheatToUnlockResultResponse.result);
    }

    return new AntiCheatToUnlockResultModel(resultModel);
  }
}
