import React from 'react';
import { useTheme } from 'styled-components';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './IconTypingtest.styles';

interface Props extends PropsWithClassName {
  size?: number;
  isHeader?: boolean;
}

const IconTypingtest = (props: Props): React.ReactElement => {
  const { size, dataTestId, isHeader } = props;
  const theme = useTheme();

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ?? 'icon-typingtest-root'}
      width={size ? size : 24}
      height={size ? size : 24}
      fill="none"
      viewBox={'0 0 512 512'}
      {...props}
    >
      <g fill="none" strokeWidth={22} strokeLinejoin="round" strokeMiterlimit={10}>
        <path
          d="M220.379 87.668a46.363 46.363 0 01-11.056-30.119c0-25.709 20.841-46.55 46.55-46.55s46.549 20.841 46.549 46.55a46.362 46.362 0 01-10.621 29.6"
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
        />
        <path
          d="M296 113v-4c0-22.091-17.909-40-40-40h0c-22.091 0-40 17.909-40 40v4"
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
        />
        <circle
          cx={255.873}
          cy={307}
          r={150}
          stroke={!isHeader ? theme.palette.icon.accent : theme.palette.icon.accentHeader}
          strokeLinecap="round"
        />
        <circle
          cx={255.873}
          cy={307}
          r={194}
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
          strokeLinecap="round"
        />
        <path
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
          d="M374.438 151.214l31.777-31.777M443.436 156.658l-31.777 31.777"
        />
        <path
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
          strokeLinecap="round"
          d="M397.763 108.11l57 57"
        />
        <path
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
          d="M69.52 157.614l28.427 28.427M135.168 148.82l-28.427-28.427"
        />
        <path
          stroke={!isHeader ? theme.palette.icon.primary : theme.palette.icon.primaryHeader}
          strokeLinecap="round"
          d="M114.237 108.11l-57 57"
        />
        <path
          stroke={!isHeader ? theme.palette.icon.accent : theme.palette.icon.accentHeader}
          strokeLinecap="round"
          d="M272.844 290.029l49.963-49.963M238.967 289.967L215.04 266.04"
        />
        <circle
          cx={255.873}
          cy={307}
          r={24}
          stroke={!isHeader ? theme.palette.icon.accent : theme.palette.icon.accentHeader}
          strokeLinecap="round"
        />
      </g>
    </Root>
  );
};

export default IconTypingtest;
