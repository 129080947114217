import seedrandom from 'seedrandom';
import styled, { css } from 'styled-components';

import ChartReward1 from '@Components/atoms/Charts/ChartReward1/ChartReward1';
import ChartReward2 from '@Components/atoms/Charts/ChartReward2/ChartReward2';
import ChartReward3 from '@Components/atoms/Charts/ChartReward3/ChartReward3';
import ChartRewardGenerated from '@Components/atoms/Charts/ChartRewardGenerated/ChartRewardGenerated';

export const Circle = styled.div<{ $isDisabled: boolean }>`
  width: 100%;
  height: 100%;

  clip-path: circle(43% at center);

  background-color: ${(props) => props.theme.palette.achievement.backgroundCircle};

  ${(props) => {
    if (props.$isDisabled) {
      return css`
        filter: blur(1px);
      `;
    }
    return css`
      filter: grayscale(100%);
      transition: filter 400ms ease-in;

      &:before {
        content: ' ';
        position: absolute;
        background: linear-gradient(100deg, transparent, #ffffff99, transparent);
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        transition: 1s;
      }
    `;
  }}
`;

export const Root = styled.div<{ $isDisabled: boolean; $size: 'small' | 'default'; $isHighlighted: boolean }>`
  background-color: ${(props) => props.theme.palette.achievement.background};
  border: 1px solid ${(props) => props.theme.palette.achievement.border};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    box-shadow ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};

  cursor: pointer;

  ${(props) =>
    props.$isHighlighted &&
    css`
      box-shadow: 0 0 15px 0 ${(props) => props.theme.palette.achievement.primaryChart};
    `}

  ${(props) =>
    props.$isDisabled
      ? css`
          filter: grayscale(100%);
        `
      : css`
          &:hover ${Circle} {
            filter: grayscale(0%);

            &:before {
              left: 100%;
            }
          }
        `}

    ${(props) => {
    if (props.$size === 'small') {
      return css`
        padding: 0.4rem;
        border-radius: 7px;
      `;
    }
    return css`
      padding: 0.8rem;
      border-radius: 13px;
    `;
  }}
`;

export const Background = styled.div<{ $size: 'small' | 'default'; $isDisabled: boolean }>`
  position: relative;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.achievement.backgroundBadge};
  transition: background-color ${(props) => props.theme.transition.theme};

  ${(props) =>
    props.$isDisabled &&
    css`
      opacity: 0.5;
      filter: contrast(80%);
    `}

  ${(props) => {
    if (props.$size === 'small') {
      return css`
        width: 2.125rem;
        height: 2.125rem;
        border-radius: 4px;
      `;
    }
    return css`
      width: 4.25rem;
      height: 4.25rem;
      border-radius: 8px;
    `;
  }}
`;

export const StyledChartRewardGenerated = styled(ChartRewardGenerated)<{ $size: 'small' | 'default' }>`
  position: absolute;
  left: 50%;

  ${(props) => {
    const generator = seedrandom(props.seed);

    if (generator() > 0.5) {
      return css`
        top: 0;
        transform: translateX(-50%) rotate(180deg) ${props.$size === 'small' && 'translateY(25%) scale(0.5)'};
      `;
    }

    return css`
      bottom: 0;
      transform: translateX(-50%) ${props.$size === 'small' && 'translateY(25%) scale(0.5)'};
    `;
  }}
`;

export const StyledChartReward1 = styled(ChartReward1)<{ $size: 'small' | 'default' }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${(props) =>
    props.$size === 'small' &&
    css`
      top: 50%;
      transform: translateX(-50%) translateY(-50%) scale(0.5);
    `}
`;

export const StyledChartReward2 = styled(ChartReward2)<{ $size: 'small' | 'default' }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${(props) =>
    props.$size === 'small' &&
    css`
      top: 50%;
      transform: translateX(-50%) translateY(-50%) scale(0.5);
    `}
`;

export const StyledChartReward3 = styled(ChartReward3)<{ $size: 'small' | 'default' }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${(props) =>
    props.$size === 'small' &&
    css`
      top: 50%;
      transform: translateX(-50%) translateY(-50%) scale(0.5);
    `}
`;

export const Icon = styled.div<{ $src: string }>`
  background: url(${(props) => props.$src});
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 1;
  mix-blend-mode: screen;
`;
