import { WinnerResponse } from '@Api-generated';

export default class WinnerModel implements WinnerResponse {
  public competitionsWon: number;
  public lastWonCompetitionId: string;

  constructor(competitionsWon: number, lastWonCompetitionId: string) {
    this.competitionsWon = competitionsWon;
    this.lastWonCompetitionId = lastWonCompetitionId;
  }

  public static hydrateFromApi(winnerResponse: WinnerResponse) {
    return new WinnerModel(winnerResponse.competitionsWon, winnerResponse.lastWonCompetitionId);
  }
}
