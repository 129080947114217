import styled from 'styled-components';

import IconButton from '@Components/atoms/IconButton';

export const Root = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  gap: ${(props) => props.theme.space.default_16};
`;

export const StyledIconButton = styled(IconButton)`
  margin-top: ${(props) => props.theme.space.small_8};
  align-self: start;
`;
