import { FlawlessResponse } from '@Api-generated';

export default class FlawlessModel implements FlawlessResponse {
  public wpm: number;
  public languageIso: string;
  public testsTaken: number;

  constructor(wpm: number, languageIso: string, testsTaken: number) {
    this.wpm = wpm;
    this.languageIso = languageIso;
    this.testsTaken = testsTaken;
  }

  public static hydrateFromApi(flawlessResponse: FlawlessResponse) {
    return new FlawlessModel(flawlessResponse.wpm, flawlessResponse.languageIso, flawlessResponse.testsTaken);
  }
}
