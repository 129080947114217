import RepeaterParticipatedCompetitionModel from '@Api/models/AchievementMetaData/RepeaterParticipatedCompetitionModel';

import { RepeaterResponse } from '@Api-generated';

export default class RepeaterModel implements RepeaterResponse {
  public participatedCompetitions: RepeaterParticipatedCompetitionModel[];

  constructor(participatedCompetitions: RepeaterParticipatedCompetitionModel[]) {
    this.participatedCompetitions = participatedCompetitions;
  }

  public static hydrateFromApi(repeaterResponse: RepeaterResponse) {
    const participatedCompetitionsModels = repeaterResponse.participatedCompetitions.map((participatedCompetition) =>
      RepeaterParticipatedCompetitionModel.hydrateFromApi(participatedCompetition)
    );

    return new RepeaterModel(participatedCompetitionsModels);
  }
}
