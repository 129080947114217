import CompetitorModel from '@Api/models/AchievementMetaData/CompetitorModel';
import FlawlessModel from '@Api/models/AchievementMetaData/FlawlessModel';
import GrinderModel from '@Api/models/AchievementMetaData/GrinderModel';
import PracticerModel from '@Api/models/AchievementMetaData/PracticerModel';
import RepeaterModel from '@Api/models/AchievementMetaData/RepeaterModel';
import TypingSpeedAdvancedModel from '@Api/models/AchievementMetaData/TypingSpeedAdvancedModel';
import TypingSpeedNormalModel from '@Api/models/AchievementMetaData/TypingSpeedNormalModel';
import WinnerModel from '@Api/models/AchievementMetaData/WinnerModel';
import UserModel from '@Api/models/UserModel';

import {
  CompetitorResponse,
  FlawlessResponse,
  GrinderResponse,
  PracticerResponse,
  RepeaterResponse,
  TypingSpeedAdvancedResponse,
  TypingSpeedNormalResponse,
  UserAchievementResponse,
  WinnerResponse,
} from '@Api-generated';

// todo: replace with generated enum from backend as soon as native enums are supported in OpenApi
export enum AchievementType {
  Progress_TypingTest_TypingSpeedNormal = 'progress_typingTest_typingSpeedNormal',
  Progress_TypingTest_TypingSpeedAdvanced = 'progress_typingTest_typingSpeedAdvanced',
  Progress_TypingTest_Grinder = 'progress_typingTest_grinder',
  Progress_TypingTest_Flawless = 'progress_typingTest_flawless',
  Progress_Competition_Competitor = 'progress_competition_competitor',
  Progress_Competition_Winner = 'progress_competition_winner',
  Progress_Competition_Repeater = 'progress_competition_repeater',
  Progress_TextPractice_Practicer = 'progress_textPractice_practicer',
  Progress_Multiplayer_Racer = 'progress_multiplayer_racer',
  Progress_Multiplayer_Brawler = 'progress_multiplayer_brawler',

  Reward_Theme_WhoTurnedTheLightsOff = 'reward_theme_whoTurnedTheLightsOff',
  Reward_CustomTypingTest_Fanboy = 'reward_customTypingTest_fanboy',
  Reward_GameMode_VarietyVoyager = 'reward_gameMode_varietyVoyager',
  Reward_GameMode_OneArmBehindMyBack = 'reward_gameMode_oneArmBehindMyBack',
  Reward_GameMode_Afk = 'reward_gameMode_afk',
  Reward_General_Asdf = 'reward_general_asdf',
  Reward_Result_Scientist = 'reward_result_scientist',
  Reward_General_StayAWhileAndListen = 'reward_general_stayAWhileAndListen',

  Reward_TextPractice_QuickBrownFox = 'reward_textPractice_quickBrownFox',
  Reward_Social_OneOfUs = 'reward_social_oneOfUs',
  Reward_Theme_LetThereBeLight = 'reward_theme_letThereBeLight',
  Reward_GameMode_Skippy = 'reward_gameMode_skippy',
  Reward_GameMode_MorningShift = 'reward_gameMode_morningShift',
  Reward_GameMode_NightShift = 'reward_gameMode_nightShift',
  Reward_GameMode_Pacemaker = 'reward_gameMode_pacemaker',
  Reward_GameMode_HappyNewYear = 'reward_gameMode_happyNewYear',
  Reward_Social_Superfan = 'reward_social_superfan',
  Reward_Social_ProfileDescription = 'reward_social_profileDescription',
  Reward_GameMode_ClimbingTheLadder = 'reward_gameMode_climbingTheLadder',
  Reward_TextPractice_TheNumbers = 'reward_textPractice_theNumbers',
}

export enum AchievementCategory {
  Progress = 'progress',
  Reward = 'reward',
}

export type AchievementMetaData =
  | TypingSpeedNormalModel
  | TypingSpeedAdvancedModel
  | GrinderModel
  | FlawlessModel
  | CompetitorModel
  | RepeaterModel
  | WinnerModel
  | PracticerModel;

export default class UserAchievementModel implements UserAchievementResponse {
  public id: string;
  public title: string;
  public description: string;
  public createdAt: string;
  public updatedAt: string;
  public metaData: AchievementMetaData | null;
  public achievementType: AchievementType;
  public category: AchievementCategory;
  public level: number;
  public levelMax: number | null;
  public userPercentage: number;
  public progress: number | null;
  public isCompleted: boolean;
  public isVisible: boolean;
  public user: UserModel;

  constructor(
    id: string,
    title: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    metaData: AchievementMetaData | null,
    achievementType: AchievementType,
    category: AchievementCategory,
    level: number,
    levelMax: number | null,
    userPercentage: number,
    progress: number | null,
    isCompleted: boolean,
    isVisible: boolean,
    user: UserModel
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.metaData = metaData;
    this.achievementType = achievementType;
    this.category = category;
    this.level = level;
    this.levelMax = levelMax;
    this.userPercentage = userPercentage;
    this.progress = progress;
    this.isCompleted = isCompleted;
    this.isVisible = isVisible;
    this.user = user;
  }

  public static hydrateFromApi(userAchievementResponse: UserAchievementResponse) {
    const userModel = UserModel.hydrateFromApi(userAchievementResponse.user);

    const achievementType = userAchievementResponse.achievementType as AchievementType;
    const category = userAchievementResponse.category as AchievementCategory;

    const metaDataModel = this.hydrateSerializable(userAchievementResponse, achievementType);

    return new UserAchievementModel(
      userAchievementResponse.id,
      userAchievementResponse.title,
      userAchievementResponse.description,
      userAchievementResponse.createdAt,
      userAchievementResponse.updatedAt,
      metaDataModel,
      achievementType,
      category,
      userAchievementResponse.level,
      userAchievementResponse.levelMax,
      userAchievementResponse.userPercentage,
      userAchievementResponse.progress,
      userAchievementResponse.isCompleted,
      userAchievementResponse.isVisible,
      userModel
    );
  }

  public static hydrateSerializable(
    userAchievementResponse: UserAchievementResponse,
    achievementType: AchievementType
  ): AchievementMetaData | null {
    switch (achievementType) {
      case AchievementType.Progress_TypingTest_TypingSpeedNormal:
        return TypingSpeedNormalModel.hydrateFromApi(userAchievementResponse.metaData as TypingSpeedNormalResponse);
      case AchievementType.Progress_TypingTest_TypingSpeedAdvanced:
        return TypingSpeedAdvancedModel.hydrateFromApi(userAchievementResponse.metaData as TypingSpeedAdvancedResponse);
      case AchievementType.Progress_TypingTest_Grinder:
        return GrinderModel.hydrateFromApi(userAchievementResponse.metaData as GrinderResponse);
      case AchievementType.Progress_TypingTest_Flawless:
        return FlawlessModel.hydrateFromApi(userAchievementResponse.metaData as FlawlessResponse);
      case AchievementType.Progress_Competition_Competitor:
        return CompetitorModel.hydrateFromApi(userAchievementResponse.metaData as CompetitorResponse);
      case AchievementType.Progress_Competition_Winner:
        return WinnerModel.hydrateFromApi(userAchievementResponse.metaData as WinnerResponse);
      case AchievementType.Progress_Competition_Repeater:
        return RepeaterModel.hydrateFromApi(userAchievementResponse.metaData as RepeaterResponse);
      case AchievementType.Progress_TextPractice_Practicer:
        return PracticerModel.hydrateFromApi(userAchievementResponse.metaData as PracticerResponse);
      case AchievementType.Reward_Theme_WhoTurnedTheLightsOff:
      case AchievementType.Reward_CustomTypingTest_Fanboy:
      case AchievementType.Reward_GameMode_OneArmBehindMyBack:
      case AchievementType.Reward_GameMode_VarietyVoyager:
      case AchievementType.Reward_General_Asdf:
      case AchievementType.Reward_Result_Scientist:
      case AchievementType.Reward_General_StayAWhileAndListen:
      case AchievementType.Reward_GameMode_Afk:
      case AchievementType.Reward_TextPractice_QuickBrownFox:
      case AchievementType.Reward_Social_OneOfUs:
      case AchievementType.Reward_Theme_LetThereBeLight:
      case AchievementType.Reward_GameMode_Skippy:
      case AchievementType.Reward_GameMode_MorningShift:
      case AchievementType.Reward_GameMode_NightShift:
      case AchievementType.Reward_GameMode_Pacemaker:
      case AchievementType.Reward_GameMode_HappyNewYear:
      case AchievementType.Reward_Social_Superfan:
      case AchievementType.Reward_Social_ProfileDescription:
      case AchievementType.Reward_GameMode_ClimbingTheLadder:
      case AchievementType.Reward_TextPractice_TheNumbers:
        return null;
      case AchievementType.Progress_Multiplayer_Racer:
      case AchievementType.Progress_Multiplayer_Brawler:
        throw new Error('Unexpected Error: Achievement Type is not supported yet');
    }
  }
}
