// have to be defined in a separate file to be accessible in the api
export enum ShareMode {
  Competition = 'competition',
  TypingTest = 'typing-test',
  TextPractice = 'text-practice',
  Achievement = 'achievement',
}

export enum ShareType {
  Image = 'image',
  Video = 'video',
  Chart = 'chart',
}

export enum ImageUploadPurpose {
  ShareTypingTestResultChart = 'share_typing-test_result_chart',
  ShareTextPracticeResultChart = 'share_text-practice-result_chart',
  ShareCompetitionResultChart = 'share_competition-result_chart',
}
