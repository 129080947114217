import { CompetitionResultNotificationResponse } from '@Api-generated';

export default class CompetitionResultNotificationModel implements CompetitionResultNotificationResponse {
  public competitionId: string;
  public participants: number;
  public position: number;
  public urlHash: string;

  constructor(competitionId: string, participants: number, position: number, urlHash: string) {
    this.competitionId = competitionId;
    this.participants = participants;
    this.position = position;
    this.urlHash = urlHash;
  }

  public static hydrateFromApi(competitionResultNotificationResponse: CompetitionResultNotificationResponse) {
    return new CompetitionResultNotificationModel(
      competitionResultNotificationResponse.competitionId,
      competitionResultNotificationResponse.participants,
      competitionResultNotificationResponse.position,
      competitionResultNotificationResponse.urlHash
    );
  }
}
