import UserAchievementModel from '@Api/models/UserAchievementModel';

import { AchievementUnlockedNotificationResponse } from '@Api-generated';

export default class AchievementUnlockedNotificationModel implements AchievementUnlockedNotificationResponse {
  public userAchievement: UserAchievementModel;

  constructor(userAchievement: UserAchievementModel) {
    this.userAchievement = userAchievement;
  }

  public static hydrateFromApi(achievementUnlockedResponse: AchievementUnlockedNotificationResponse) {
    const userAchievementModel = UserAchievementModel.hydrateFromApi(achievementUnlockedResponse.userAchievement);

    return new AchievementUnlockedNotificationModel(userAchievementModel);
  }
}
