import { GrinderResponse } from '@Api-generated';

export default class GrinderModel implements GrinderResponse {
  public testsTaken: number;

  constructor(testsTaken: number) {
    this.testsTaken = testsTaken;
  }

  public static hydrateFromApi(grinderResponse: GrinderResponse) {
    return new GrinderModel(grinderResponse.testsTaken);
  }
}
