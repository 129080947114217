import { CompetitionWonResponse } from '@Api-generated';

export default class CompetitionWonModel implements CompetitionWonResponse {
  public competitionId: string;
  public participants: number;
  public position: number;
  public urlHash: string;

  constructor(competitionId: string, urlHash: string) {
    this.competitionId = competitionId;
    this.urlHash = urlHash;
  }

  public static hydrateFromApi(competitionWonResponse: CompetitionWonResponse) {
    return new CompetitionWonModel(competitionWonResponse.competitionId, competitionWonResponse.urlHash);
  }
}
