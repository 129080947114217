import { RepeaterParticipatedCompetitionResponse } from '@Api-generated';

export default class RepeaterParticipatedCompetitionModel implements RepeaterParticipatedCompetitionResponse {
  public competitionId: string;
  public testsTaken: number;

  constructor(competitionId: string, testsTaken: number) {
    this.competitionId = competitionId;
    this.testsTaken = testsTaken;
  }

  public static hydrateFromApi(repeaterParticipatedCompetitionResponse: RepeaterParticipatedCompetitionResponse) {
    return new RepeaterParticipatedCompetitionModel(
      repeaterParticipatedCompetitionResponse.competitionId,
      repeaterParticipatedCompetitionResponse.testsTaken
    );
  }
}
