import dynamic from 'next/dynamic';
import React from 'react';
import seedrandom from 'seedrandom';

import { PropsWithClassName } from '@Components/helper';

import { Path, Root } from './ChartRewardGenerated.styles';

interface Props extends PropsWithClassName {
  seed?: string;
  height: number;
  width: number;
}

const ChartRewardGenerated = (props: Props): React.ReactElement => {
  const { seed, height, width } = props;

  const generator = seedrandom(seed);

  const rnd = () => (seed ? generator() : Math.random());

  const frequency = rnd() * 0.075;
  const amplitude = rnd() * (height / 4) + height / 4;
  const phase = rnd() * 20;

  let pathData = `M 0 ${height / 2} `;

  for (let x = 0; x < width; x++) {
    const y = amplitude * Math.sin(frequency * x + phase) + height / 2;
    pathData += `L ${x} ${y}`;
  }

  pathData += `L ${width} ${height} L 0 ${height} Z`;

  return (
    <Root className={props.className} data-testid="chart-reward-generated-root">
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <Path d={pathData} opacity={0.9} />
      </svg>
    </Root>
  );
};

export default dynamic(() => Promise.resolve(ChartRewardGenerated), {
  ssr: false,
});
