'use client';

import { IconShare } from '@tabler/icons-react';
import React from 'react';

import UserAchievementModel from '@Api/models/UserAchievementModel';

import IconButton from '@Components/atoms/IconButton';
import Row from '@Components/atoms/Row';
import { PropsWithClassName } from '@Components/helper';
import { getDynamicSubRoute } from '@Components/helper/url';
import ProgressAchievement from '@Components/organisms/ProgressAchievement/ProgressAchievement';
import { Routes } from '@Components/Routes';

import useRouter from '@Helpers/i18n/useRouter';
import { ShareMode } from '@Helpers/types/share';
import { TextSize } from '@Helpers/types/text';

import { StyledText } from './ProgressAchievementNotification.styles';

interface Props extends PropsWithClassName {
  message: string;
  achievement: UserAchievementModel;
}

const ProgressAchievementNotification = (props: Props): React.ReactElement => {
  const { message, achievement } = props;
  const router = useRouter();

  return (
    <div className={props.className} data-testid={props.dataTestId ?? 'progress-achievement-notification-root'}>
      <Row noGutters xsBetween>
        <ProgressAchievement userAchievementModel={achievement} hideTooltip />
        <IconButton
          onClick={() => router.push(getDynamicSubRoute(Routes.Share, ShareMode.Achievement, achievement.id, 'image'))}
        >
          <IconShare />
        </IconButton>
      </Row>
      <StyledText size={TextSize.Medium}>{message}</StyledText>
    </div>
  );
};

export default ProgressAchievementNotification;
